import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {PhysicianTeam} from '../../../../models/physician-team';
import {compareByIds} from '../../../../utils/select-option.utils';
import {Observable} from 'rxjs';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-physician-team-select',
    templateUrl: 'physician-team-select.component.html',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PhysicianTeamSelectComponent), multi: true},
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => PhysicianTeamSelectComponent), multi: true},
    ],
})
export class PhysicianTeamSelectComponent implements ControlValueAccessor, Validator {
    @Input() physicianTeam: number | number[] | PhysicianTeam;
    @Input() multi = false;
    @Input() bindValue = null;
    @Input() disabled = false;
    @Input() defaultBodySelectStyle = true;
    @Input() excludeInactive = false;
    @Input() physicianTeams$: Observable<PhysicianTeam[]> = this.userAuth.physicianTeams$.pipe(
        map(teams => teams.filter(PhysicianTeam.isAssigned))
    );
    @Input() placeholder = 'Select Physician Team';
    @Input() classList: string;
    @Input() required = false;

    @Output() physicianTeamChange = new EventEmitter<number | number[]>();

    comparePhysicianTeamObjById = compareByIds;

    constructor(private userAuth: UserAuthService) {
    }

    onPhysicianTeamChange(selection) {
        this.propagateChange(selection);
        this.physicianTeamChange.emit(selection || null);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.required) return null;

        if (this.required && !control.value) return {required: true};

        return null;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    propagateChange = (_: any) => {
    };

    writeValue(obj: any): void {
        this.physicianTeam = obj;
    }
}
