<ng-container *ngIf="physicianTeams$ | async as physicianTeams">
    <ng-select [items]="physicianTeams"
               [(ngModel)]="physicianTeam"
               (ngModelChange)="onPhysicianTeamChange($event)"
               bindLabel="facility.name"
               [bindValue]="bindValue"
               [multiple]="multi"
               [disabled]="disabled"
               class="{{classList}}"
               [ngClass]="{'body-select wide-input': defaultBodySelectStyle}"
               placeholder="Physician Team"
               [required]="required"
               [compareWith]="comparePhysicianTeamObjById">
        <ng-template ng-option-tmp let-item="item">
            <div class="d-flex align-items-center"><span class="mr-2 o-hidden" [style.opacity]="item.facility.is_inactive ? 0.5 : 1">{{item.facility.name}}</span>
                <small class="text-muted ml-auto">{{item.user|humanName}}<span *ngIf="item.specialty">, {{item.specialty?.profession_name}}</span></small>
            </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <div class="d-flex align-items-center mr-2" [class.text-danger]="item.error"><span class="mx-2 o-hidden">{{item.facility?.name}}</span>
                <small class="ml-auto" [class.text-muted]="!item.error" [class.text-danger]="item.error">{{item.user|humanName}}</small>
            </div>
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </ng-template>
    </ng-select>
</ng-container>
